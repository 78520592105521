export default function LinkedinIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.9897 8.88593C13.9867 8.36668 13.9612 7.84539 13.9072 7.32918C13.7106 5.43303 12.5523 4.3874 10.6482 4.35895C9.5164 4.34168 8.56795 4.72883 7.78963 5.72873C7.78963 5.3873 7.78148 5.17289 7.79167 4.95848C7.80389 4.68006 7.68266 4.58047 7.40454 4.58555C6.69448 4.59876 5.98339 4.59775 5.27333 4.58555C4.99724 4.58047 4.88926 4.67701 4.89028 4.9595C4.89639 7.84234 4.89741 10.7242 4.88926 13.607C4.88824 13.9129 5.00845 14.0013 5.29676 13.9962C6.02007 13.983 6.74338 13.9779 7.46669 13.9982C7.79778 14.0074 7.9078 13.9017 7.90373 13.5643C7.88947 12.0533 7.87826 10.5413 7.91188 9.03124C7.92207 8.59938 8.03005 8.1482 8.19305 7.74682C8.41412 7.20419 9.08649 6.92272 9.73238 7.00706C10.3854 7.09343 10.7501 7.39421 10.8856 8.04049C10.963 8.40936 10.9865 8.79448 10.9895 9.17249C11.0007 10.6327 11.0007 12.0929 10.9895 13.5531C10.9875 13.8763 11.0863 14.0074 11.4266 13.9972C12.1366 13.9769 12.8477 13.9728 13.5578 13.9982C13.9235 14.0114 14.003 13.8682 13.9999 13.5318C13.9856 11.9842 14.0009 10.4346 13.9897 8.88593Z'
        fill='#39A949'
      />
      <path
        d='M2.81609 4.58439C2.0938 4.59963 1.37049 4.60471 0.648203 4.58236C0.306922 4.5722 0.21931 4.69718 0.222367 5.02337C0.237648 6.44396 0.229498 7.86556 0.229498 9.28615C0.229498 10.7199 0.23561 12.1537 0.224404 13.5875C0.222367 13.8731 0.293679 14.0032 0.603378 13.996C1.35114 13.9798 2.09992 13.9818 2.84768 13.995C3.13394 14.0001 3.22563 13.8812 3.22563 13.613C3.22156 10.7332 3.22156 7.85235 3.22665 4.97256C3.22767 4.67381 3.10644 4.57931 2.81609 4.58439Z'
        fill='#39A949'
      />
      <path
        d='M1.683 4.97824e-05C0.727411 0.00716289 -0.00506733 0.761152 2.64045e-05 1.7326C0.00512014 2.68881 0.757974 3.4245 1.7268 3.42044C2.67627 3.41637 3.44441 2.64917 3.44237 1.7072C3.44135 0.748958 2.66201 -0.00706333 1.683 4.97824e-05Z'
        fill='#39A949'
      />
    </svg>
  )
}
