/* eslint-disable no-undef */
import { makeStyles, createStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

import Logo from './Logo'
import TwitterIcon from './TwitterIcon.smava'
import FacebookIcon from './FacebookIcon.smava'
import LinkedinIcon from './LinkedinIcon.smava'

interface SmavaFooterProps {
  extended?: boolean
}

export const spacing = (space = 1) => `${8 * space}px`

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      backgroundColor: 'white',
    },
    logoLink: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    logo: {
      width: '90px',
    },
    navigationListWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    socialNavigationWrapper: ({ extended }: SmavaFooterProps) => ({
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        width: extended ? '100%' : 'auto',
      },
    }),
    legalNavigationList: {
      display: 'flex',
      padding: `${spacing(2)} 0`,
      fontSize: '14px',
      justifyContent: 'center',

      [theme.breakpoints.down('sm')]: {
        marginRight: `${spacing(4)} 0`,

        ' a': {
          padding: `${spacing()} 0`,
        },
      },
    },
    legalNavigationListAnchor: {
      paddingRight: `${spacing(2)}`,
      color: 'rgb(50,50,50)',

      ':first-of-type': {
        paddingLeft: 0,
      },

      ':hover': {
        color: 'rgb(151, 151, 151)',
      },

      [theme.breakpoints.down('sm')]: {
        paddingRight: 0,
      },
    },
    legalListRow: {
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    socialNavigationList: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      padding: `0 ${spacing(4)}`,

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        padding: 0,
      },
    },
    socialNavigationListAnchor: {
      padding: `0 ${spacing(2)}`,

      ':hover': {
        opacity: 0.75,
      },
    },
    legalAddress: {
      fontSize: `12px !important`,
      margin: `${spacing()} 0`,
    },
    internalLink: {
      cursor: 'pointer',
    },
  }),
)

const currentYear = new Date().getFullYear()

export default function Footer({ extended = true }: SmavaFooterProps) {
  const {
    navigationListWrapper,
    socialNavigationWrapper,
    logoLink,
    logo,
    socialNavigationList,
    socialNavigationListAnchor,
    legalNavigationList,
    legalNavigationListAnchor,
    legalListRow,
    legalAddress,
    internalLink,
  } = useStyles({ extended })

  return (
    <div className='wrapper'>
      <Container>
        <div className={`${navigationListWrapper}`}>
          <div className={`${socialNavigationWrapper}`}>
            <a
              className={`${logoLink}`}
              aria-label='smava - Ihr Kredit ist da.'
            >
              <div className={`${logo}`}>
                <Logo color={!extended ? '#dedede' : undefined} />
              </div>
            </a>
            {extended && (
              <div className={`${socialNavigationList}`}>
                <Link
                  className={`${socialNavigationListAnchor}`}
                  href='https://www.facebook.com/smava.de'
                  target='_blank'
                >
                  <FacebookIcon />
                </Link>
                <Link
                  className={`${socialNavigationListAnchor}`}
                  href='https://twitter.com/smava'
                  target='_blank'
                >
                  <TwitterIcon />
                </Link>
                <Link
                  className={`${socialNavigationListAnchor}`}
                  href='https://de.linkedin.com/company/smava'
                  target='_blank'
                >
                  <LinkedinIcon />
                </Link>
              </div>
            )}
          </div>
          <div className={`${legalNavigationList}`}>
            <div className={`${legalListRow}`}>
              <Link
                className={`${legalNavigationListAnchor}`}
                href='https://www.smava.de/agb'
                target='_blank'
              >
                AGB
              </Link>

              <Link
                className={`${legalNavigationListAnchor}`}
                href='https://www.smava.de/impressum'
                target='_blank'
              >
                Impressum
              </Link>
              <Link
                className={`${legalNavigationListAnchor}`}
                href='https://www.smava.de/datenschutz'
                target='_blank'
              >
                Datenschutz
              </Link>

              <a
                role='button'
                className={`${internalLink} ${legalNavigationListAnchor}`}
                onClick={() => window.Optanon.ToggleInfoDisplay()}
              >
                Cookie Einstellungen
              </a>
            </div>
          </div>
        </div>
        {extended && (
          <Typography
            color='inherit'
            variant='body1'
            className={`${legalAddress}`}
          >
            © {currentYear} smava.de | Palisadenstraße 90 | 10243 Berlin
          </Typography>
        )}
      </Container>
    </div>
  )
}
