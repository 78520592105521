export default function FacebookIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width='8'
      height='16'
      viewBox='0 0 8 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.65193 11.9836C1.65193 10.7307 1.64698 9.47849 1.65617 8.2263C1.65759 8.01548 1.59816 7.94898 1.38734 7.95747C0.996827 7.97303 0.605605 7.95605 0.215091 7.96454C0.0566215 7.96879 -0.00138971 7.92493 2.51974e-05 7.75726C0.00780718 6.97552 0.00709973 6.19379 0.000732651 5.41205C-0.000682256 5.26207 0.0445947 5.21255 0.196697 5.21538C0.596408 5.22458 0.996827 5.20406 1.39583 5.22387C1.62717 5.23519 1.67457 5.15312 1.67032 4.93876C1.65617 4.3438 1.65617 3.74812 1.66961 3.15315C1.68518 2.4266 1.88044 1.74815 2.3134 1.15743C2.86026 0.410355 3.63846 0.0686546 4.53692 0.0283297C5.45944 -0.0127026 6.38408 0.00993592 7.30802 3.1572e-05C7.43677 -0.00138333 7.47498 0.0446012 7.47498 0.16982C7.47073 0.960046 7.47002 1.75098 7.47568 2.5412C7.4771 2.68199 7.43111 2.72514 7.29316 2.72231C6.84888 2.71524 6.4046 2.71878 5.96103 2.72019C5.3696 2.72231 5.01587 3.02652 4.97908 3.61158C4.94937 4.08062 4.9713 4.55249 4.95998 5.02366C4.95574 5.19133 5.05549 5.18001 5.16656 5.18001C5.84147 5.1793 6.51708 5.18637 7.19199 5.17505C7.39008 5.17152 7.45517 5.2175 7.43253 5.42549C7.34763 6.21077 7.27477 6.99745 7.20756 7.78485C7.19199 7.96242 7.11134 7.99638 6.95146 7.99355C6.41875 7.98506 5.88604 7.98789 5.35262 7.99143C4.96988 7.99355 5.00738 7.93554 5.00738 8.32605C5.00526 10.7774 5.00243 13.2294 5.01304 15.6807C5.01445 15.9397 4.94795 16.004 4.69185 15.9991C3.77712 15.9821 2.86167 15.9814 1.94694 15.9998C1.68942 16.0048 1.64698 15.9177 1.6491 15.6878C1.65688 14.4526 1.65193 13.2181 1.65193 11.9836Z'
        fill='#39A949'
      />
    </svg>
  )
}
