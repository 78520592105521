import { memo } from 'react'
import { makeStyles, createStyles } from '@mui/styles'
import { useMediaQuery, useTheme } from '@mui/material'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Phone from '@mui/icons-material/Phone'

import { getPhoneNumber, isSmava } from '../../utils/getTenantInfo.utils'
import { SupportedTheme } from '../../theme'

interface PhoneContactProps {
  onClick?: () => void
  type?: string
}
interface styleProps {
  isMobile: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&.v2': {
        '& $phoneContact': {
          color:
            theme.name === SupportedTheme.Mobilede
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          flexDirection: 'row-reverse',
          position: 'relative',
          right: 0,
          paddingLeft: '10px',
        },
        '& $text': {
          textAlign: 'left',
        },
        '& $icon': {
          border: 'none',
        },
      },
    },
    phoneContact: {
      textDecoration: 'none',
      height: '80px',
      color: theme.palette.primary.main,
      '&:hover': {
        '& > div': {
          borderColor: theme.palette.primary.main,
        },
        '& #header-hours': {
          maxHeight: '2rem',
        },
      },
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      right: theme.spacing(3),
      paddingLeft: 0,
    },
    text: {
      marginRight: theme.spacing(1),
      textAlign: 'end',
    },
    textFlex: {
      flexDirection: 'column-reverse',
      display: 'flex',
    },
    hours: {
      color: theme.palette.grey[500],
      maxHeight: 0,
      transition: 'max-height 300ms ease 0s',
      fontSize: '12px',
      overflow: 'hidden',
      display: 'block',
    },
    icon: {
      width: ({ isMobile }: styleProps) => (isMobile ? 'auto' : '40px'),
      height: ({ isMobile }: styleProps) => (isMobile ? 'auto' : '40px'),
      borderRadius: '50%',
      border: ({ isMobile }: styleProps) =>
        isMobile ? 'none' : '2px solid rgb(229, 232, 234)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'border-color 300ms ease 0s',
    },
  }),
)

const PhoneContact = ({ type = 'v1', onClick }: PhoneContactProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery<Theme>(theme.breakpoints.down('sm'))
  const isTypeV1 = type === 'v1'

  const { root, phoneContact, text, textFlex, hours, icon } = useStyles({
    isMobile,
  })
  const { tel, display: displayPhone } = getPhoneNumber()

  const phoneIcon = (
    <div className={icon}>
      <Phone color={isTypeV1 ? 'primary' : 'secondary'} />
    </div>
  )

  if (isMobile && isSmava) {
    return (
      <div className={`${root} ${type}`}>
        {
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <a href={`tel:${tel}`} className={phoneContact}>
            {phoneIcon}
          </a>
        }
      </div>
    )
  }

  if (isMobile) {
    return (
      <div className={`${root} ${type}`}>
        {
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            className={phoneContact}
            onClick={onClick}
            role='button'
            tabIndex={0}
          >
            {phoneIcon}
          </div>
        }
      </div>
    )
  }

  return (
    <div className={`${root} ${type}`}>
      <a href={`tel:${tel}`} className={phoneContact}>
        <span className={text}>
          <div className={isTypeV1 ? '' : textFlex}>
            <Typography variant='body2'>Kostenlose Beratung</Typography>
            <Typography variant='body1'>{displayPhone}</Typography>
          </div>
          {isTypeV1 ? (
            <time
              id='header-hours'
              className={hours}
              dateTime='Mo-Su 08:00-20:00'
            >
              Mo - So von 08 - 20 Uhr
            </time>
          ) : (
            ''
          )}
        </span>
        {phoneIcon}
      </a>
    </div>
  )
}

export default memo(PhoneContact)
